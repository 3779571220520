import React from 'react';

const TotalAssetList = ({
  totalWCInvestor,
  totalCompany,
  totalNanoAssetInvestors,
  totalNanoAssets,
  totalPendingBuyTokenRequests,
  totalPendingSellTokenRequests,
}) => {
  return (
    <div className="flex gap-8 text-white mt-8 w-full">
      <div className="border-[#383838] border bg-transparent px-8 py-10 flex flex-col gap-6 rounded-lg w-full">
        <p>Total WC Investor</p>
        <h2 className="text-8xl">{totalWCInvestor}</h2>
      </div>
      <div className="border-[#383838] border bg-transparent px-8 py-10 flex flex-col gap-6 rounded-lg w-full">
        <p>Total Company</p>
        <h2 className="text-8xl">{totalCompany}</h2>
      </div>
      <div className="border-[#383838] border bg-transparent px-8 py-10 flex flex-col gap-6 rounded-lg w-full">
        <p>Total Nano Asset Investors</p>
        <h2 className="text-8xl">{totalNanoAssetInvestors}</h2>
      </div>
      <div className="border-[#383838] border bg-transparent px-8 py-10 flex flex-col gap-6 rounded-lg w-full">
        <p>Total Nano Assets</p>
        <h2 className="text-8xl">{totalNanoAssets}</h2>
      </div>
      <div className="border-[#383838] bg-transparent px-8 py-8 flex flex-col gap-3 rounded-lg w-full grow min-w-[350px] justify-center">
        <p className='text-4xl'>All Requests</p>
        <div className="flex justify-between items-center">
          <p>Pending Purchase: <span className="text-yellow-400">{totalPendingBuyTokenRequests}</span></p>
          <p>Pending Sale: <span className="text-yellow-400">{totalPendingSellTokenRequests}</span></p>
        </div>
      </div>
    </div>
  );
};

export default TotalAssetList;
